import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'

import ScreenHolder from '../../Partials/Layouts/ScreenHolder/ScreenHolder';
import useAxiosAuthInstance from '../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance';
import { RESET_PASSWORD_API } from '../../../Utilities/APIs/AuthAPIs/AuthAPIs';
import './Auth.css';
import { toast } from 'react-toastify';

function RecoverPassThree() {

    const { token } = useParams()
    const navigate = useNavigate()
    const [newPass, setNewPass] = useState('')
    const [newPass2, setNewPass2] = useState('')

    const axiosAuthInstance = useAxiosAuthInstance()

    async function handleSubmit(e) {
        e.preventDefault()

        if (!newPass) {
            toast.error("Password is required.", { autoClose: 1500 });
            return;
          }
      
          if (!newPass2) {
            toast.error("Confirm password is required.", { autoClose: 1500 });
            return;
          }
      
          const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
          if (!passwordRegex.test(newPass)) {
            toast.error("Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.", { autoClose: 1500 });
            return;
          }
      
          if (newPass !== newPass2) {
            toast.error("Password Must Be Similiar", { autoClose: 1500 });
            // console.log("Passwords Do Not Match");
            return;
          }

        if (newPass !== newPass2) {
            // console.log('Passwords Do Not Match')
        }

        const formBody = {
            token,
            newPassword: newPass
        }

        const changePassword = await axiosAuthInstance.patch(RESET_PASSWORD_API, formBody)

        if (changePassword) {
            setNewPass('')
            setNewPass2('')
            navigate('/login')
        }

    }

    return (
        <ScreenHolder>
            <div className="form_wrapper">
                <h3>Recover Password</h3>
                <form action="/">
                    <div className="input_group">
                        <label className="input_field_label caption bold">new password</label>
                        <input
                            type="password"
                            className="input_field body_text"
                            placeholder='Enter password'
                            value={newPass}
                            onChange={e => setNewPass(e.target.value)}
                        />
                    </div>
                    <div className="input_group">
                        <label className="input_field_label caption bold">confirm password</label>
                        <input
                            type="password"
                            className="input_field body_text"
                            placeholder='Enter password'
                            value={newPass2}
                            onChange={e => setNewPass2(e.target.value)}
                        />
                    </div>
                    <button type='submit' className='btn_text submit' onClick={handleSubmit}>change password</button>
                </form>
                <Link to='/login' className='btn_text forgot_pass'>Back to Login</Link>
                <ul className="instruction-ul">
                    <li>
                        Password must be at least 8 characters long.
                    </li>
                    <li>
                        Password must include an uppercase letter.
                    </li>
                    <li>
                        Password must include an lowercase letter.
                    </li>
                    <li>
                        Password must include a number.
                    </li>
                    <li>
                        Password must include a special character.
                    </li>
                </ul>
            </div>
        </ScreenHolder>
    )
}

export default RecoverPassThree