import './InputBoilerplate.css';

function InputBoilerplate({ label, type, value, placeholder, setState, fieldName, inputIsTextArea }) {
  return (
    <div className="input_group">
      <label className="input_field_label caption bold">{label}</label>
      {!inputIsTextArea &&
        <input
          className='input_field'
          type={type}
          value={value}
          placeholder={placeholder}

          onChange={e => {
            const inputValue = e.target.value;
            if (fieldName) {
              // Update parcel dimensions by spreading the previous state
              setState(prevState => ({ ...prevState, [fieldName]: inputValue }));
            } else {
              // Update normal fields
              setState(inputValue);
            }
          }}
        />

        //   // onChange={e => setState(e.target.value)}
        //   onChange={e => setState(prevState => ({ ...prevState, [fieldName]: e.target.value }))}
        // />
      }
      {inputIsTextArea &&
        <textarea
          className='input_field'
          value={value}
          placeholder={placeholder}
          onChange={e => setState(e.target.value)}
        />
      }
    </div>
  )
}

export default InputBoilerplate